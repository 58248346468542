import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../../api";

const initialState = {
  zones: [],
  teamZones: [],
  isLoading: false,
};

export const createZone = createAsyncThunk(
  "create/zone",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/settings/create_zone", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getTeamsZone = createAsyncThunk(
  "fetch/branches",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/settings/get_zones_on_team", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getUsersZone = createAsyncThunk(
  "fetch/zone",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/settings/get_teams_on_branch_user");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const zoneReducer = createSlice({
  name: "zone",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createZone.pending, (zone, action) => {
        zone.isLoading = true;
      })
      .addCase(createZone.fulfilled, (zone, action) => {
        zone.isLoading = false;
        zone.zones = action.payload;
      })
      .addCase(createZone.rejected, (zone, action) => {
        zone.isLoading = false;
      });
    builder
      .addCase(getTeamsZone.pending, (zone, action) => {
        zone.isLoading = true;
      })
      .addCase(getTeamsZone.fulfilled, (zone, action) => {
        zone.isLoading = false;
        zone.teamZones = action.payload;
      })
      .addCase(getTeamsZone.rejected, (zone, action) => {
        zone.isLoading = false;
      });
    builder
      .addCase(getUsersZone.pending, (zone, action) => {
        zone.isLoading = true;
      })
      .addCase(getUsersZone.fulfilled, (zone, action) => {
        zone.isLoading = false;
        zone.zones = action.payload;
      })
      .addCase(getUsersZone.rejected, (zone, action) => {
        zone.isLoading = false;
      });
  },
});

export default zoneReducer.reducer;
