import axios from "axios";

const connection = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});
// Request Interceptors

connection.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem("user");
    const { token } = JSON.parse(user);

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // console.log("config", config);
    return config;
  },
  (error) => {
    process.env.NODE_ENV === "development" && console.log("Error--->", error);
    return Promise.reject(error);
  }
);

connection.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;

    // if (response && response.status === 401) {
    //   window.location.replace("/auth/landing"); // Change the URL accordingly
    // }

    return Promise.reject(error);
  }
);
export default connection;
