import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../api";

import { toast } from "react-toastify";

const initialState = {
  data: [],
  loading: {
    getLoading: false,
    openLoading: false,
    closeLoading: false,
  },
};

export const getLastSession = createAsyncThunk(
  "get/lastSession",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/financial/get_last_session");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const openSession = createAsyncThunk(
  "post/openSession",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.post("/financial/open_session");
      if (response.status === 200) {
        toast.success(response.data.message, {
          theme: "colored",
          draggable: false,
        });
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const closeSession = createAsyncThunk(
  "post/closeSession",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.post("/financial/close_session");
      if (response.status === 200) {
        toast.success(response.data.message, {
          theme: "colored",
          draggable: false,
        });
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const financeSlice = createSlice({
  name: "financeManager",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLastSession.pending, (state, action) => {
        state.loading.getLoading = true;
      })
      .addCase(getLastSession.fulfilled, (state, action) => {
        state.loading.getLoading = false;
        state.data = action.payload;
      })
      .addCase(getLastSession.rejected, (state) => {
        state.loading.getLoading = false;
      });

    builder
      .addCase(openSession.pending, (state, action) => {
        state.loading.openLoading = true;
      })
      .addCase(openSession.fulfilled, (state, action) => {
        state.loading.openLoading = false;
        state.data = action.payload;
      })
      .addCase(openSession.rejected, (state) => {
        state.loading.openLoading = false;
      });

    builder
      .addCase(closeSession.pending, (state) => {
        state.loading.closeLoading = true;
      })
      .addCase(closeSession.fulfilled, (state, action) => {
        state.loading.closeLoading = false;
        state.data = action.payload;
      })
      .addCase(closeSession.rejected, (state) => {
        state.loading.closeLoading = false;
      });
  },
});

export default financeSlice.reducer;
