// store.js
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/authSlice";
import countryReducer from "./features/settings/countrySlice";
import regionReducer from "./features/settings/regionSlice";
import teamReducer from "./features/settings/teamSlice";
import zoneReducer from "./features/settings/zoneSlice";
import branchReducer from "./features/settings/branchSlice";
import branchManagerReducer from "./features/branchManagerSlice";
import userReducer from "./features/UserSlice";
import finaceManagerReducer from "./features/financeManagerSlice";
import LoanStatusSlice from "./features/LoanStatusSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    country: countryReducer,
    region: regionReducer,
    team: teamReducer,
    zone: zoneReducer,
    branch: branchReducer,
    branchManager: branchManagerReducer,
    user: userReducer,
    financeManager: finaceManagerReducer,
    loanStatus: LoanStatusSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActionPaths: ["payload"],
        ignoredPaths: ["auth.keycloak"],
      },
    }),
});

export default store;
