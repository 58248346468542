import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../../api";

const initialState = {
  regions: [],
  countryRegions: [],
  isLoading: false,
  selectedCountry: "",
};

export const createRegion = createAsyncThunk(
  "create/region",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/settings/create_region", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getRegions = createAsyncThunk(
  "fetch/regions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/settings/get_all_regions");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getCountryRegions = createAsyncThunk(
  "fetch/countryRegion",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/settings/get_regions_on_country", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const regionReducer = createSlice({
  name: "region",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createRegion.pending, (region, action) => {
        region.isLoading = true;
      })
      .addCase(createRegion.fulfilled, (region, action) => {
        region.isLoading = false;
        region.regions = action.payload.data;
      })
      .addCase(createRegion.rejected, (region, action) => {
        region.isLoading = false;
      });
    builder
      .addCase(getRegions.pending, (region, action) => {
        region.isLoading = true;
      })
      .addCase(getRegions.fulfilled, (region, action) => {
        region.isLoading = false;
        region.regions = action.payload;
      })
      .addCase(getRegions.rejected, (region, action) => {
        region.isLoading = false;
      });
    builder
      .addCase(getCountryRegions.pending, (region, action) => {
        region.isLoading = true;
      })
      .addCase(getCountryRegions.fulfilled, (region, action) => {
        region.isLoading = false;
        region.countryRegions = action.payload;
      })
      .addCase(getCountryRegions.rejected, (region, action) => {
        region.isLoading = false;
      });
  },
});

export const { setSelectedCountry, setRegions } = regionReducer.actions;

export default regionReducer.reducer;
