import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../api";
import { toast } from "react-toastify";

const initialState = {
  users: [],
  branchAssign: [],
  teamAssign: [],
  isLoading: false,
  loading: false,
  message: "",
  isSuccess: false,
};

export const getAllUsers = createAsyncThunk(
  "get/users",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/users/get_all_unssigne_users");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getBranchAssignment = createAsyncThunk(
  "get/getBranchAssignment",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/users/get_all_branch_assign");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getAllTeamAssign = createAsyncThunk(
  "get/getAllTeamAssign",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/users/get_all_team_assign");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.value);
    }
  }
);

export const assignUserToBranch = createAsyncThunk(
  "post/assignUser",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/users/Assign_user_to_branch", data);
      if (response.status === 200) {
        toast.success(response.data.message, {
          theme: "colored",
          draggable: false,
        });
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error("Fields cannot be empty!", {
          theme: "colored",
          draggable: false,
        });
      }
      return rejectWithValue(error.response);
    }
  }
);
export const assignUserToTeam = createAsyncThunk(
  "post/assignUserToTeam",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/users/Assign_user_to_team", data);
      if (response.status === 200) {
        toast.success(response.data.message, {
          theme: "colored",
          draggable: false,
        });
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error("Fields cannot be empty!", {
          theme: "colored",
          draggable: false,
        });
      }
      return rejectWithValue(error.response);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (user) => {
        user.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (user, action) => {
        user.isLoading = false;
        user.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (user) => {
        user.isLoading = false;
      });

    builder
      .addCase(getBranchAssignment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBranchAssignment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.branchAssign = action.payload;
      })
      .addCase(getBranchAssignment.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(assignUserToBranch.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
      })
      .addCase(assignUserToBranch.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(assignUserToBranch.rejected, (state) => {
        state.loading = false;
        state.isSuccess = false;
      });

    builder
      .addCase(getAllTeamAssign.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllTeamAssign.fulfilled, (state, action) => {
        state.isLoading = false;
        state.teamAssign = action.payload;
      })
      .addCase(getAllTeamAssign.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(assignUserToTeam.pending, (state) => {
        state.loading = true;
        state.isSuccess = false;
      })
      .addCase(assignUserToTeam.fulfilled, (state, action) => {
        state.loading = false;
        state.isSuccess = true;
        state.message = action.payload;
      })
      .addCase(assignUserToTeam.rejected, (state) => {
        state.loading = false;
        state.isSuccess = false;
      });
  },
});

export default userSlice.reducer;
