import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

const SplashScreen = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };
    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offiline", handleOnlineStatusChange);
    };
  });
  return (
    <div className="d-flex align-items-center justify-content-center flex-column vh-100">
      <div>
        <Spinner className="mr-4" size="sm" animation="grow" variant="info" />
        <Spinner className="mr-4" size="sm" animation="grow" variant="danger" />
        <Spinner size="sm" animation="grow" variant="success" />
      </div>
      <div>
        {isOnline ? (
          <span className="small text-seconadry">
            Hope MicroCredit Initializing...
          </span>
        ) : (
          <span className="small text-seconadry">
            We apologize for the inconvenience. It seems that your device is not
            connected to the internet. Please check your network connection and
            try again.
          </span>
        )}
      </div>
    </div>
  );
};
export default SplashScreen;
