import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../../api";
import { toast } from "react-toastify";

const initialState = {
  countries: [],
  isLoading: false,
  loading: false,
  message: "",
};

export const createCountry = createAsyncThunk(
  "create/country",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/settings/create_country", data);
      console.log(response.data);
      if (response.status === 201) {
        toast.success(response.data.message, { theme: "colored" });
      }
      console.log(response);
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error("Fields cannot be empty!", { theme: "colored" });
      }
      return rejectWithValue(error.response);
    }
  }
);

export const getCountries = createAsyncThunk(
  "fetch/country",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/settings/get_all_countries");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const countryReducer = createSlice({
  name: "country",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCountry.pending, (country, action) => {
        country.loading = true;
      })
      .addCase(createCountry.fulfilled, (country, action) => {
        country.loading = false;
        country.message = action.payload;
      })
      .addCase(createCountry.rejected, (country, action) => {
        country.loading = false;
      });
    builder
      .addCase(getCountries.pending, (country, action) => {
        country.isLoading = true;
      })
      .addCase(getCountries.fulfilled, (country, action) => {
        country.isLoading = false;
        country.countries = action?.payload;
      })
      .addCase(getCountries.rejected, (country, action) => {
        country.isLoading = false;
      });
  },
});

export default countryReducer.reducer;
