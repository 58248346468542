import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../api";

const initialState = {
  branchManagerData: [],
  isLoading: false,
  message: "",
};

export const getPendingCustomer = createAsyncThunk(
  "getPending/customer",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/branch/pending_customers");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const branchManagerReducer = createSlice({
  name: "branchManager",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPendingCustomer.pending, (branchManager, action) => {
        branchManager.isLoading = true;
      })
      .addCase(getPendingCustomer.fulfilled, (branchManager, action) => {
        branchManager.isLoading = false;
        branchManager.data = action.payload;
        branchManager.message = action.payload.message;
      })
      .addCase(getPendingCustomer.rejected, (branchManager, action) => {
        branchManager.isLoading = false;
      });
  },
});

export default branchManagerReducer.reducer;
