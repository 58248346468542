import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Keycloak_conn from "Keycloak_conn";
import axios from "axios";
import SplashScreen from "views/SplashScreen";
// import IdleTimerComponent from "IdleTimer";

export const AuthContext = createContext();

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [keycloak, setKeycloak] = useState(null);
  const [menu, setMenu] = useState([]);
  const isRun = useRef(false);

  useEffect(() => {
    const initializeKeycloak = async () => {
      const keycloakInstance = Keycloak_conn();

      try {
        await keycloakInstance.init({ onLoad: "login-required" });

        if (keycloakInstance.authenticated) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_BASE_URL}users/user_access`,
            // "https://34.242.41.32:8884/users/user_access",

            {
              headers: { Authorization: `Bearer ${keycloakInstance.token}` },
            }
          );

          setMenu(response.data);
          setAuthenticated(keycloakInstance.authenticated);
          setKeycloak(keycloakInstance);
          let user = {
            token: keycloakInstance.token,
            refreshToken: keycloakInstance.refreshToken,
          };
          localStorage.setItem("user", JSON.stringify(user));
        }
        if (keycloakInstance.onTokenExpired) {
          keycloakInstance.updateToken(50);
        }
      } catch (error) {
        console.error("Keycloak initialization error:", error);
      }
    };

    if (!isRun.current) {
      isRun.current = true;
      initializeKeycloak();
    }
  }, [isRun.current]);

  const contextValue = {
    authenticated,
    menu,
    keycloak,
  };

  return keycloak ? (
    <>
      <AuthContext.Provider value={contextValue}>
        {children}
      </AuthContext.Provider>
      {/* <IdleTimerComponent /> */}
    </>
  ) : (
    <SplashScreen />
  );
};
