import { useAuthContext } from "context/AuthContext";
import { useEffect } from "react";

const IdleTimerComponent = () => {
  const { keycloak } = useAuthContext();

  const checkForInactivity = () => {
    const expireTime = localStorage.getItem("expireTime");
    if (expireTime < Date.now()) {
      keycloak.logout();
    }
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + 20 * 60 * 1000;
    localStorage.setItem("expireTime", expireTime);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      checkForInactivity();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    updateExpireTime();

    window.addEventListener("click", updateExpireTime);
    window.addEventListener("keypress", updateExpireTime);
    window.addEventListener("scroll", updateExpireTime);
    window.addEventListener("mousemove", updateExpireTime);

    return () => {
      window.removeEventListener("click", updateExpireTime);
      window.removeEventListener("keypress", updateExpireTime);
      window.removeEventListener("scroll", updateExpireTime);
      window.removeEventListener("mousemove", updateExpireTime);
    };
  }, []);

  return null;
};

export default IdleTimerComponent;
