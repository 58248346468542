// src/store/features/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    authenticated: false,
    keycloak: null,
  },
  reducers: {
    setAuthenticatedq: (state, action) => {
      state.authenticated = action.payload;
    },
    setKeycloak: (state, action) => {
      state.keycloak = action.payload;
      let user = {
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      };
      localStorage.setItem("user", JSON.stringify(user));
    },
  },
});

export const { setAuthenticatedq, setKeycloak } = authSlice.actions;

export default authSlice.reducer;
