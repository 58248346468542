import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../../api";

const initialState = {
  branches: [],
  regionBranches: [],
  isLoading: false,
};

export const createBranch = createAsyncThunk(
  "create/branch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/settings/create_branch", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getBranches = createAsyncThunk(
  "fetch/branches",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/settings/get_all_branches");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getRegionBranches = createAsyncThunk(
  "fetch/branch",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/settings/get_branches_on_region", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const branchReducer = createSlice({
  name: "branch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createBranch.pending, (branch, action) => {
        branch.isLoading = true;
      })
      .addCase(createBranch.fulfilled, (branch, action) => {
        branch.isLoading = false;
        branch.branches = action.payload;
      })
      .addCase(createBranch.rejected, (branch, action) => {
        branch.isLoading = false;
      });
    builder
      .addCase(getBranches.pending, (branch, action) => {
        branch.isLoading = true;
      })
      .addCase(getBranches.fulfilled, (branch, action) => {
        branch.isLoading = false;
        branch.branches = action.payload;
      })
      .addCase(getBranches.rejected, (branch, action) => {
        branch.isLoading = false;
      });
    builder
      .addCase(getRegionBranches.pending, (branch, action) => {
        branch.isLoading = true;
      })
      .addCase(getRegionBranches.fulfilled, (branch, action) => {
        branch.isLoading = false;
        branch.regionBranches = action.payload;
      })
      .addCase(getRegionBranches.rejected, (branch, action) => {
        branch.isLoading = false;
      });
  },
});

export default branchReducer.reducer;
