import Keycloak from "keycloak-js";
// hi this is test comment
function KeyCloakConnection() {
  const keycloak = new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: process.env.REACT_APP_REALM,
    clientId: process.env.REACT_APP_CLIENT_ID,
    protocol: process.env.REACT_APP_PROTOCAL,
    checkLoginIframe: false,
  });
  return keycloak;
}
export default KeyCloakConnection;
// END OF KEYCLOAK CONFIGURATION
