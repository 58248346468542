import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../api";
import { toast } from "react-toastify";
import { actions } from "react-table";

export const index = createAsyncThunk(
  "post/status_names",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/loan_status/list_name_for_status",
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const status = createAsyncThunk(
  "post/check_status",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/loan_status/check_status", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const callCenterStatus = createAsyncThunk(
  "post/check_callCenter",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/loan_status/call_center", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const branchManagerStatus = createAsyncThunk(
  "post/check_manager",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/loan_status/branch_manager", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const teamStatus = createAsyncThunk(
  "post/check_team",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/loan_status/team", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const analystStatus = createAsyncThunk(
  "post/check_analyst",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/loan_status/credit_analyst", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const disbursedStatus = createAsyncThunk(
  "post/check_disbursed",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/loan_status/disbursed", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const repaymentStatus = createAsyncThunk(
  "post/check_repayment",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/loan_status/repayment", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const loanStatusSlice = createSlice({
  name: "loanSlice",
  initialState: {
    data: [],
    checkStatus: [],
    loading: false,
    loadCheck: false,
    description: {
      center: [],
      manager: [],
      team: [],
      analyst: [],
      disbursed: [],
      repayment: [],
    },
    isLoading: {
      center: false,
      manager: false,
      team: false,
      analyst: false,
      disbursed: false,
      repayment: false,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(index.pending, (loanSlice, action) => {
        loanSlice.loading = true;
      })
      .addCase(index.fulfilled, (loanSlice, action) => {
        loanSlice.loading = false;
        loanSlice.data = action.payload;
      })
      .addCase(index.rejected, (loanSlice, action) => {
        loanSlice.loading = false;
        console.log(action.payload);
      });

    builder
      .addCase(status.pending, (loanSlice) => {
        loanSlice.loadCheck = true;
      })
      .addCase(status.fulfilled, (loanSlice, action) => {
        loanSlice.loadCheck = false;
        loanSlice.checkStatus = action.payload;
      })
      .addCase(status.rejected, (loanSlice, action) => {
        loanSlice.loadCheck = false;
        console.log(action.payload);
      });
    builder
      .addCase(callCenterStatus.pending, (loanSlice) => {
        loanSlice.isLoading.center = true;
      })
      .addCase(callCenterStatus.fulfilled, (loanSlice, action) => {
        loanSlice.isLoading.center = false;
        loanSlice.description.center = action.payload;
      })
      .addCase(callCenterStatus.rejected, (loanSlice, action) => {
        loanSlice.isLoading.center = false;
      });
    builder
      .addCase(branchManagerStatus.pending, (loanSlice) => {
        loanSlice.isLoading.manager = true;
      })
      .addCase(branchManagerStatus.fulfilled, (loanSlice, action) => {
        loanSlice.isLoading.manager = false;
        loanSlice.description.manager = action.payload;
      })
      .addCase(branchManagerStatus.rejected, (loanSlice, action) => {
        loanSlice.isLoading.manager = false;
      });

    builder
      .addCase(teamStatus.pending, (loanSlice) => {
        loanSlice.isLoading.team = true;
      })
      .addCase(teamStatus.fulfilled, (loanSlice, action) => {
        loanSlice.isLoading.team = false;
        loanSlice.description.team = action.payload;
      })
      .addCase(teamStatus.rejected, (loanSlice, action) => {
        loanSlice.isLoading.team = false;
      });

    builder
      .addCase(analystStatus.pending, (loanSlice) => {
        loanSlice.isLoading.analyst = true;
      })
      .addCase(analystStatus.fulfilled, (loanSlice, action) => {
        loanSlice.isLoading.analyst = false;
        loanSlice.description.analyst = action.payload;
      })
      .addCase(analystStatus.rejected, (loanSlice, action) => {
        loanSlice.isLoading.analyst = false;
      });

    builder
      .addCase(disbursedStatus.pending, (loanSlice) => {
        loanSlice.isLoading.disbursed = true;
      })
      .addCase(disbursedStatus.fulfilled, (loanSlice, action) => {
        loanSlice.isLoading.disbursed = false;
        loanSlice.description.disbursed = action.payload;
      })
      .addCase(disbursedStatus.rejected, (loanSlice, action) => {
        loanSlice.isLoading.disbursed = false;
      });

    builder
      .addCase(repaymentStatus.pending, (loanSlice) => {
        loanSlice.isLoading.repayment = true;
      })
      .addCase(repaymentStatus.fulfilled, (loanSlice, action) => {
        loanSlice.isLoading.repayment = false;
        loanSlice.description.repayment = action.payload;
      })
      .addCase(repaymentStatus.rejected, (loanSlice, action) => {
        loanSlice.isLoading.repayment = false;
      });
  },
});

export default loanStatusSlice.reducer;
