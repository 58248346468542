import React, { Suspense, lazy, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/demo.css";

import { Provider } from "react-redux";
import store from "strore";
// const App App from "App";
const App = lazy(() => import("App"));
import { AuthProvider } from "context/AuthContext";
import SplashScreen from "views/SplashScreen";
import IdleTimerComponent from "IdleTimerComponent";

const Root = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  return (
    <React.StrictMode>
      <Provider store={store}>
        <AuthProvider>
          <Suspense fallback={<SplashScreen />}>
            <IdleTimerComponent />
            {isOnline ? <App /> : <SplashScreen />}
          </Suspense>
        </AuthProvider>
      </Provider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<Root />);
