import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../../../api";

const initialState = {
  teams: [],
  branchTeams: [],
  isLoading: false,
};

export const createTeam = createAsyncThunk(
  "create/team",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/settings/create_team", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getBranchTeams = createAsyncThunk(
  "fetch/branchTeam",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post("/settings/get_teams_on_branch", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getUserTeam = createAsyncThunk(
  "fetch/team",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/settings/get_teams_on_branch_user");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const teamReducer = createSlice({
  name: "team",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createTeam.pending, (team, action) => {
        team.isLoading = true;
      })
      .addCase(createTeam.fulfilled, (team, action) => {
        team.isLoading = false;
        team.teams = action.payload.data;
        console.log(action.payload);
      })
      .addCase(createTeam.rejected, (team, action) => {
        team.isLoading = false;
        console.log(action.payload);
      });
    builder
      .addCase(getBranchTeams.pending, (team, action) => {
        team.isLoading = true;
      })
      .addCase(getBranchTeams.fulfilled, (team, action) => {
        team.isLoading = false;
        team.branchTeams = action.payload;
      })
      .addCase(getBranchTeams.rejected, (team, action) => {
        team.isLoading = false;
      });
    builder
      .addCase(getUserTeam.pending, (team, action) => {
        team.isLoading = true;
      })
      .addCase(getUserTeam.fulfilled, (team, action) => {
        team.isLoading = false;
        team.teams = action.payload.data;
        console.log(action.payload);
      })
      .addCase(getUserTeam.rejected, (team, action) => {
        team.isLoading = false;
        console.log(action.payload);
      });
  },
});

export default teamReducer.reducer;
